import React, { ChangeEvent, useCallback, useState } from "react";
import shared from "styles/shared.module.css";
import styles from "./Sattlments.module.css";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SattlmentStatus } from "datasource/Types";
import { Pagination } from "components/pagination";
import { SattlmentsTable } from "./SattlmentsTable";
import { PER_PAGE_DEFAULT } from "config/base";
import { getSattlments } from "datasource/Api";
import { PERMISSIONS } from "config/permissions";
import WithCheckPermission from "components/WithCheckPermission";
import DateRangeInput from "components/inputs/DateRangeInput";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { getFormatedDate } from "utils/date";
import { useQuery } from "@tanstack/react-query";

const Sattlments = () => {
  const [sattlmentType, setSattlmentType] = useState<string>("ALL");
  const [date, setDate] = useState<DateRange<dayjs.Dayjs>>([null, null]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const {
    data: sattlmentsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["sattlments", search, sattlmentType, page, [...date]],
    queryFn: () =>
      getSattlments({
        pagingData: { page: page, perPage: PER_PAGE_DEFAULT },
        filters: {
          sattlment_type: sattlmentType,
          search,
          from_date: getFormatedDate(date[0]?.toDate()),
          to_date: getFormatedDate(date[1]?.toDate()),
        },
      }),
  });

  const handleSattlmentTypeChanged = (event: SelectChangeEvent) => {
    setSattlmentType(event.target.value);
  };

  const handleDateChanged = (date: DateRange<dayjs.Dayjs>) => {
    setDate(date);
  };

  const handleSearchChanged = useCallback((event: any) => {
    const search = event.target.value;
    setSearch(search);
  }, []);

  const pageChanged = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>All Settlements</div>
      <div className={styles.filters}>
        <TextField
          sx={{ flex: 1 }}
          label="Search by key words"
          variant="outlined"
          placeholder="Search by sattlment id"
          value={search}
          size="small"
          onChange={handleSearchChanged}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "grey" }} />,
          }}
        />
        <DateRangeInput value={date} onChange={handleDateChanged} />

        <Select
          style={{ width: 200 }}
          labelId="Settlement type"
          size="small"
          id="demo-simple-select"
          onChange={handleSattlmentTypeChanged}
          value={sattlmentType}
        >
          <MenuItem value={"ALL"}>All Settlements</MenuItem>
          <MenuItem value={SattlmentStatus.SATTLED}>Sattled</MenuItem>
          <MenuItem value={SattlmentStatus.PENDING}>Pending</MenuItem>
        </Select>
      </div>
      <div className={shared.spacer} />
      <Paper sx={{ padding: "20px" }}>
        <SattlmentsTable
          loading={isLoading}
          sattlments={sattlmentsData?.data.data || []}
          refresh={refetch}
        />
        <Box className={styles.paging}>
          <div className={styles.showingResults}>
            {sattlmentsData?.data?.data.length} results out of{" "}
            {sattlmentsData?.data?.total}
          </div>
          <Pagination
            defaultPage={1}
            count={sattlmentsData?.data?.last_page || 0}
            onChange={pageChanged}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Sattlments, PERMISSIONS.view_settlement);
