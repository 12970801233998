import { TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Loader from "core/Loader";

type GenericTableType = {
  rows: TableRowsType[];
  heads: TableHeadType[];
  loading?: boolean;
};
type TableRowType = string | any;
type TableRowsType = {
  [key: string]: TableRowType;
};
export type TableHeadType = {
  key: string;
  name: string | JSX.Element;
  align?: "right" | "left" | "center";
  permission?: string;
  renderCell?: (row: TableRowType) => JSX.Element;
};
const GenericTable = ({ heads, rows, loading }: GenericTableType) => {
  return (
    <TableContainer>
      {!loading && (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {heads.map((head) => (
                <TableCell align={head.align}>{head.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                {heads.map((head) => {
                  if (head.renderCell && row[head.key]) {
                    return (
                      <TableCell align={head.align}>
                        {head.renderCell(row[head.key])}
                      </TableCell>
                    );
                  }
                  if (row[head.key])
                    return (
                      <TableCell align={head.align}>{row[head.key]}</TableCell>
                    );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {loading && <Loader />}
    </TableContainer>
  );
};

export default GenericTable;
