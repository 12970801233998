import React, { FC } from "react";
import typography from "styles/typography.module.css";
import button from "styles/button.module.css";
import { Box, TextField } from "@mui/material";

interface CancelModalConfirmProps {
  orderId: number;
  amount: number;
  onAmountChange: (value: number) => void;
  onConfirm: () => void;
  submitting: boolean;
  confirmed: boolean;
}
const CaptureModalConfirm: FC<CancelModalConfirmProps> = ({
  orderId,
  onConfirm,
  onAmountChange,
  amount,
  submitting,
  confirmed,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: 14,
        width: 418,
        marginBottom: 14,
      }}
    >
      {!confirmed ? (
        <>
          <h2
            className={`${typography.title} ${typography.textPrimary}`}
            style={{ fontWeight: 500 }}
          >
            Capture Order
          </h2>
          <div style={{ fontWeight: 300 }}>
            You are about to Capture order with id {orderId} , are you sure you
            want to Capture the order?
          </div>
          <Box className={typography.regularText} color={"green"}>
           {`(The amount must be less than the order amount)`}
          </Box>
          <TextField
            onChange={(e) => onAmountChange(parseFloat(e.target.value))}
            value={amount}
            label="amount"
            type="number"
            size="small"
          />
          <div className={typography.errorText}></div>
          <button
            disabled={submitting}
            onClick={onConfirm}
            style={{ marginTop: 5 }}
            className={`${button.buttonPrimary} ${
              submitting ? button.disabled : ""
            }`}
          >
            Capture Order
          </button>
        </>
      ) : (
        <>
          <h2
            className={`${typography.title} ${typography.regularText}`}
            style={{ fontWeight: 500 }}
          >
            Captured successfully
          </h2>
          <div style={{ fontWeight: 300 }}>
            The order has been Captured successfully
          </div>
        </>
      )}
    </div>
  );
};

export default CaptureModalConfirm;
